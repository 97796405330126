import { useTranslation } from "react-i18next";
import { Flex, Heading, Text } from "@chakra-ui/react";
import {
  BootcampMentor,
  BootcampRelease,
  BootcampSpeaker,
  EntityName,
} from "@/schemaTypes";
import {
  unorderedList,
  UnorderedListArray,
} from "../UnorderedList/unorderedList";
import { ProfilesArray } from "../Profile/ProfilesArray/profilesArray";

interface BootcampScheduleProps {
  release: BootcampRelease;
}

export const Schedule = ({ release }: BootcampScheduleProps) => {
  const { t } = useTranslation();

  const mentors: BootcampMentor[] = release.mentors ?? [];
  const speakers: BootcampSpeaker[] = release.speakers ?? [];

  const willLearnItems: unorderedList[] = [
    {
      title: t("What will you learn?"),
      list: [
        t("Live deep dive sessions with experts."),
        t("Accompaniment of teachers between live sessions."),
        t(
          "Personalized support from specialized tutors during the bootcamp period."
        ),
        t("Analysis of real cases for product and supplier search."),
        t(
          "Downloadable material, specially designed for the product launch stage."
        ),
        t("Access to recorded sessions for three (3) months."),
      ],
    },
  ];
  const willLearnItemsMasterMind: unorderedList[] = [
    {
      title: t("What will you learn?"),
      list: [
        t(
          "Gestión eficaz de las finanzas personales: Cómo crear un presupuesto, controlar los gastos, ahorrar de manera automatizada y utilizar la deuda de forma responsable para mejorar la salud financiera personal."
        ),
        t(
          "Separación de finanzas personales y empresariales: La importancia de mantener las cuentas separadas y cómo estructurar adecuadamente las finanzas para evitar problemas y mejorar el control sobre el negocio."
        ),
        t(
          "Creación de un modelo financiero: Cómo diseñar desde cero un modelo financiero que incluya proyecciones de ventas, costos, gastos operativos y flujo de caja para la correcta planificación financiera del negocio."
        ),
        t(
          "Toma de decisiones estratégicas basadas en finanzas: Usar el modelo financiero para tomar decisiones inteligentes sobre precios, financiamiento y crecimiento, evitando errores comunes en la gestión financiera."
        ),
        t(
          "Proyecciones financieras y flujo de caja: Cómo anticipar el comportamiento financiero del negocio, realizar proyecciones precisas y mantener un flujo de caja saludable que permita la sostenibilidad del emprendimiento."
        ),
      ],
    },
  ];
  const objectivePublic: unorderedList[] = [
    {
      title: t("Who is this Bootcamp for?"),
      list: [
        t(
          "Futuros emprendedores digitales: Personas que están comenzando en el mundo del ecommerce y quieren adquirir conocimientos financieros sólidos desde el inicio para evitar errores comunes y gestionar bien su capital."
        ),
        t(
          "Emprendedores en fase de crecimiento: Dueños de negocios online que ya han lanzado su tienda y están buscando herramientas financieras para optimizar sus recursos y maximizar la rentabilidad de su emprendimiento."
        ),
        t(
          "Freelancers en ecommerce: Profesionales independientes que manejan sus propios proyectos de comercio electrónico y necesitan una mejor organización financiera para separar ingresos personales de los empresariales."
        ),
        t(
          "Pequeños empresarios que buscan escalar: Propietarios de pequeñas empresas que desean expandir su negocio, y que requieren dominar las finanzas para tomar decisiones estratégicas en cuanto a inversiones y financiamiento."
        ),
      ],
    },
  ];

  return (
    <Flex flexDir={"column"} gap={6}>
      <UnorderedListArray
        unorderedList={
          release.bootcampType === EntityName.MastermindBootcamp
            ? willLearnItemsMasterMind
            : willLearnItems
        }
      />
      {release.bootcampType !== EntityName.MastermindBootcamp && (
        <Flex flexDir={"column"} gap={2}>
          <Heading>{t("Who is this Bootcamp for?")}</Heading>
          <Text fontSize={"14px"}>
            {t(
              "Entrepreneurs who want to master the techniques for a successful product launch on Amazon, by learning how to create listings, shipping plans, and advertising campaigns."
            )}
          </Text>
        </Flex>
      )}
      {release.bootcampType === EntityName.MastermindBootcamp && (
        <UnorderedListArray unorderedList={objectivePublic} />
      )}
      {mentors.length < 0 && (
        <ProfilesArray profilesData={mentors} title={t("Your mentors")} />
      )}
      {speakers.length > 0 && (
        <ProfilesArray profilesData={speakers} title={t("Invited speakers")} />
      )}
    </Flex>
  );
};
